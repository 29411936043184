import React from 'react'
import '../App.css'
import dayjs from 'dayjs'

export function EventList(props) {
  const { events } = props

  const listItems = events.map((event) => {
    return (
      <div key={event.isoDate} className='Status-Event-Detail-List-Item'>
        <h4>{event.title}</h4>
        <h5>
          {event.pubDate} (
          {dayjs(event.pubDate).format('dddd, MMMM D, YYYY h:mm A z')})
        </h5>
        <hr />
        <p>{event.contentSnippet}</p>
      </div>
    )
  })

  return listItems
}
