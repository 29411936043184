import React, { useEffect, useState } from 'react'
import { ShareIcon, Spinner, Button, Dialog, Pill } from 'evergreen-ui'
import { EventList } from './EventList'
import CloudService from '../service/CloudService'
import DateUtils from '../service/DateUtils'
import sunny from '../assets/weather/sunny.svg'
import partlyCloudy from '../assets/weather/partly-cloudy.svg'
import cloudy from '../assets/weather/cloudy.svg'
import misty from '../assets/weather/misty.svg'
import rainy from '../assets/weather/rainy.svg'
import pouring from '../assets/weather/hard-rain.svg'
import storming from '../assets/weather/storming.svg'
import tornado from '../assets/weather/tornado.svg'

import '../App.css'

export function StatusBlock(props) {
  const { provider } = props
  const cloudService = new CloudService()
  const [events, setEvents] = useState([])
  const [detailEvents, setDetailEvents] = useState({})
  const [detailShown, setDetailShown] = useState(false)

  useEffect(() => {
    const fetchEvents = async () => {
      const newEvents = await cloudService.getFeedEvents(provider)
      setEvents(newEvents)
    }
    fetchEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getWeatherImage = (numEvents) => {
    if (numEvents === 0) {
      return sunny
    }
    if (numEvents === 1) {
      return partlyCloudy
    }
    if (numEvents === 2) {
      return cloudy
    }
    if (numEvents === 3) {
      return misty
    }
    if (numEvents === 4) {
      return rainy
    }
    if (numEvents === 5) {
      return pouring
    }
    if (numEvents > 5 && numEvents < 10) {
      return storming
    }
    if (numEvents >= 10) {
      return tornado
    }
  }

  const getNumEventsTitle = (numEvents) => {
    if (numEvents === 0) {
      return 'All Clear'
    }
    if (numEvents === 1) {
      return 'Partly Cloudy'
    }
    if (numEvents === 2) {
      return 'Cloudy'
    }
    if (numEvents === 3) {
      return 'Misting'
    }
    if (numEvents === 4) {
      return 'Rainy'
    }
    if (numEvents === 5) {
      return 'Pouring'
    }
    if (numEvents > 5 && numEvents < 10) {
      return 'Storming'
    }
    if (numEvents >= 10) {
      return 'Severe'
    }
  }

  const getNumEventsBadgeColor = (numEvents) => {
    if (numEvents === 0) {
      return 'teal'
    }
    if (numEvents <= 2) {
      return 'yellow'
    }
    if (numEvents <= 5) {
      return 'orange'
    }
    if (numEvents < 10) {
      return 'red'
    }
    return 'purple'
  }

  const loadDetailModal = (dayInfo) => {
    setDetailEvents(dayInfo)
    setDetailShown(true)
  }

  const getDetailModalTitle = (detailEvents) => {
    if (detailEvents.events) {
      if (detailEvents.events.length === 1) {
        return `${
          detailEvents.events && detailEvents.events.length
        } Incident on ${DateUtils.getWeekdayLong(7 - detailEvents.day)}`
      }
      return `${
        detailEvents.events && detailEvents.events.length
      } Incidents on ${DateUtils.getWeekdayLong(7 - detailEvents.day)}`
    }
  }

  return (
    <>
      <div
        className='Status-Block'
        style={{
          borderTop: `${provider.color} 14px solid`,
        }}
      >
        <div className='Status-Block-Header'>
          <img height={18} src={provider.icon} alt='Cloud Icon' />
          <h4 className='Status-Block-Header-Title'>{provider.name}</h4>
          <a
            href={provider.dashboard}
            target='_blank'
            rel='noopener noreferrer'
          >
            <ShareIcon size={10}></ShareIcon>
          </a>
        </div>
        {events.length === 0 ? (
          <div
            className='Status-Block-Events'
            style={{ flexDirection: 'column' }}
          >
            <Spinner style={{ alignSelf: 'center' }} />
          </div>
        ) : (
          <div className='Status-Block-Events'>
            {events.map((day, dayIndex) => {
              return (
                <div key={`${dayIndex}`} className='Status-Block-Event-Day'>
                  <h4>{DateUtils.getWeekday(7 - dayIndex)} </h4>
                  <div>
                    <img src={getWeatherImage(day.length)} alt='Weather'></img>
                    {day.length > 0 && (
                      <Pill
                        color={getNumEventsBadgeColor(day.length)}
                        position='absolute'
                        right='20px'
                        top='6px'
                        zIndex='5'
                      >
                        {day.length}
                      </Pill>
                    )}
                  </div>

                  <h3>{getNumEventsTitle(day.length)} </h3>
                  <Button
                    appearance='minimal'
                    className='Status-Block-Event-Day-Button'
                    disabled={day.length === 0}
                    onClick={() => {
                      loadDetailModal({ day: dayIndex, events: day })
                    }}
                  >
                    See More{' '}
                  </Button>
                </div>
              )
            })}
          </div>
        )}
      </div>
      <React.Fragment>
        <Dialog
          isShown={detailShown}
          title={getDetailModalTitle(detailEvents)}
          confirmLabel={'OK'}
          hasCancel={false}
          onCloseComplete={() => setDetailShown(false)}
        >
          <EventList events={detailEvents.events} />
        </Dialog>
      </React.Fragment>
    </>
  )
}
