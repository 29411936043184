import React, { useState } from 'react'
import { Button, CloudIcon, SelectMenu, Position } from 'evergreen-ui'
import { StatusList } from './components/StatusList'
import { Footer } from './components/Footer'
import CloudService from './service/CloudService'
import rssImage from './assets/rss.png'
import './App.css'

function App() {
  const cloudService = new CloudService()
  const [providers, setProviders] = useState(cloudService.getProviders(true))

  const updateProviders = (providers) => {
    cloudService.sort(providers)

    const providerNames = providers.map((p) => p.name)
    window.history.replaceState(null, '', `?providers=${providerNames}`)

    setProviders(providers)
  }

  const handleSelection = (item) => {
    let provider = cloudService.getProvider(item.name)
    let newProviders = [provider, ...providers]
    updateProviders(newProviders)
  }

  const handleDeselection = (item) => {
    const newProviders = providers.filter((provider) => {
      return provider.name !== item.name
    })
    updateProviders(newProviders)
  }

  return (
    <div className='App'>
      <div className='Content'>
        <div className='Header'>
          <div className='Header-Title'>
            <CloudIcon size={30}></CloudIcon>
            <h3>Cloud Weather</h3>
          </div>
          <SelectMenu
            isMultiSelect
            title='Filter Cloud Providers'
            options={cloudService.getFilterProviders()}
            selected={providers.map((p) => p.name)}
            onSelect={(item) => {
              handleSelection(item)
            }}
            onDeselect={(item) => {
              handleDeselection(item)
            }}
            position={Position.BOTTOM_RIGHT}
          >
            <Button>{'Filter Cloud Providers'}</Button>
          </SelectMenu>
        </div>
        <div className='Sub-Header'>
          <div className='Sub-Header-Title'>
            <h5>Subscribe to an RSS Feed for your selection:</h5>
            <a
              href={cloudService.getRssLink(providers)}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img height={24} src={rssImage} alt='RSS' />
            </a>
          </div>
        </div>
        <div className='Status-List'>
          <StatusList providers={providers} />
        </div>
      </div>
      <Footer providers={providers} />
    </div>
  )
}

export default App
