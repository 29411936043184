import React from 'react';
import CloudService from '../service/CloudService';
import rssImage from '../assets/rss.png';

import '../App.css';

export function Footer(props) {
	const { providers } = props;
	const cloudService = new CloudService();

	return (
		<div className='Footer'>
			<h5>cloudweather.dev</h5>
			<a href={cloudService.getRssLink(providers)} target='_blank' rel='noopener noreferrer'>
				<img height={24} src={rssImage} alt='RSS' />
			</a>
		</div>
	);
}
