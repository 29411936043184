import providersList from './providers.json'
import 'url-search-params-polyfill'
import * as Parser from 'rss-parser'
import { ConstantStrings } from '../Constants'
import DateUtils from './DateUtils'

const getBaseUrl = () => {
  const isLocal = window.location.href.includes('localhost')
  return isLocal
    ? 'http://127.0.0.1:5001/cloud-weather-bad03/us-central1/api'
    : 'https://us-central1-cloud-weather-bad03.cloudfunctions.net/api'
}

class CloudService {
  getProvider(name) {
    return providersList.providers[name]
  }

  getProviders(onlyQueryParams) {
    let search = new URLSearchParams(window.location.search)
    if (onlyQueryParams) {
      if (!search.has(ConstantStrings.queryParam)) {
        return Object.values(providersList.providers)
      } else if (search.get(ConstantStrings.queryParam).length === 0) {
        return []
      }
      return Object.values(providersList.providers).filter((provider) => {
        let search = new URLSearchParams(window.location.search)
        return search.get(ConstantStrings.queryParam).includes(provider.name)
      })
    }
    return Object.values(providersList.providers)
  }

  getFilterProvider(name) {
    const provider = providersList.providers[name]
    return {
      name: provider.name,
      label: provider.name,
      value: provider.name,
      icon: provider.icon,
    }
  }

  getFilterProviders(onlyQueryParams) {
    return this.getProviders(onlyQueryParams).map((provider) => ({
      name: provider.name,
      label: provider.name,
      value: provider.name,
      icon: provider.icon,
    }))
  }

  sort(providers) {
    providers.sort(function (a, b) {
      return a.name.localeCompare(b.name)
    })
  }

  async getFeedEvents(provider) {
    const url = `${getBaseUrl()}?provider=${provider.name}`
    const parser = new Parser()
    let feed
    try {
      feed = await parser.parseURL(url)
    } catch (error) {
      return []
    }

    const lastWeekEvents = []

    for (let daysAgo = 6; daysAgo >= 0; daysAgo--) {
      const [dayStart, dayEnd] = DateUtils.getDayBounds(daysAgo)
      const daysEvents = feed.items.filter((i) => {
        const itemDate = new Date(i.isoDate)
        return itemDate > dayStart && itemDate < dayEnd
      })
      lastWeekEvents.push(daysEvents)
    }

    return lastWeekEvents
  }

  getRssLink(providers) {
    const providerNames = providers.map((p) => p.name).join(',')
    return `${getBaseUrl()}/combined?providers=${providerNames}`
  }
}

export default CloudService
