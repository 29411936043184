import React from 'react';
import { StatusBlock } from './StatusBlock';

import '../App.css';

export function StatusList(props) {
	const { providers } = props;

	const listItems = providers.map((provider) => {
		return <StatusBlock key={provider.name} provider={provider} />;
	});

	return listItems;
}
